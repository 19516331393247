<template>
  <v-container fluid>
    <h1 class="text-center">Vuokranet yhteistyössä</h1>
    <p class="text-center">
      Vuokranetin kautta löytyvät palvelut ja palveluntarjoajat. Lisätietoa löydät suoraan
      vuokranetin-järjestelmästä tai info@vuokranet.fi
    </p>
    <div v-for="category in categories" :key="category.id">
      <h2 class="mt-1">{{ category.heading }}</h2>
      <v-row>
        <v-col
          v-for="collaborator in category.collaborators"
          :key="collaborator.id"
          cols="12"
          sm="6"
          md="4"
          align="center"
        >
          <v-card class="h-full">
            <v-card-text class="pa-3">
              <div class="collaborator-image">
                <img :src="collaborator.logo" alt="Company Logo" contain />
              </div>
              <h2 class="mt-1">{{ collaborator.name }}</h2>
              <p>{{ collaborator.description }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  title: "Yhteistyökumppanit",

  data() {
    return {
      categories: [
        {
          heading: "Rahoitus ja laskutus",
          collaborators: [
            {
              id: 1,
              name: "Ropo Capital",
              logo: "/images/collaborators/ropocapital.png",
              description:
                "Palvelumallin perustana on pitkälle automatisoitu laskutuksen kokonaisuus, johon sisältyy koko laskun elinkaaren hallinta laskujen välityksestä reskontravalvontaan, muistutus- sekä perintätoimiin. Ropo Capital toimii saumattomasti yhteen Vuokranetin kanssa.",
            },
            {
              id: 2,
              name: "Netvisor",
              logo: "/images/collaborators/netvisor.png",
              description:
                "Vuokranetistä voidaan lähettää laskutus- ja asiakasdataa rajapinnan kautta Netvisoriin ja vastaanottaa maksutiedot Vuokranettiin. Netvisor toimii normaaliin tapaan kirjanpidon, taloushallinnon ja palkanlaskennan master-järjestelmänä Vuokranetin toiminnanohjausjärjestelmän rinnalla.",
            },
            {
              id: 3,
              name: "Open banking | GoCardless / Nordigen",
              logo: "/images/collaborators/nordigen.png",
              description:
                "Välittäjien lähtevien maksujen tarkistus tehdään avoimen rajapinnan kautta omalta pankkitililtä. Nordigen on yhdistetty yli 2300 eri pankkiin.",
            },
            {
              id: 4,
              name: "Sijoituslainan kilpailutus",
              logo: "/images/collaborators/",
              description: "Kilpailuta sijoituslainasi ja pyydä tarjous",
            },
          ],
        },
        {
          heading: "Sähköinen allekirjoitus",
          collaborators: [
            {
              id: 5,
              name: "Visma Sign",
              logo: "/images/collaborators/vismasign.png",
              description:
                "Allekirjoita vuokrasopimukset tai muut dokumentit Visma Signin vahvaa tunnistautumista käyttäen.",
            },
            {
              id: 6,
              name: "SignHero",
              logo: "/images/collaborators/signhero.png",
              description:
                "Allekirjoita vuokrasopimukset tai muut dokumentit Signherolla kiinteällä kuukausimaksulla. Allekirjoitukset eivät vaadi vahvaa tunnistumista.",
            },
          ],
        },
        {
          heading: "Vuokranantajalle",
          collaborators: [
            {
              id: 7,
              name: "Digivakuus ja Vuokralaisraportti",
              logo: "/images/collaborators/vuokratieto.png",
              description:
                "Digivakuudella poistat vuokrakohteesta vuokralaisen rahavakuuden. Vuokralaisraportti selvittää vuokralaisen maksukyvyn ja tulot tilitapahtumiin perustuen. Digivakuuden voi tilata suoraan Vuokranetin kautta.",
            },
          ],
        },
        {
          heading: "Vuokralaiselle",
          collaborators: [
            {
              id: 8,
              name: "Sähkösopimukset ja kotivakuutukset",
              logo: "company3.png",
              description: "Description of Company 3...",
            },
          ],
        },
        {
          heading: "Vuokravälitys ja managerointi",
          collaborators: [
            {
              id: 9,
              name: "Linear",
              logo: "/images/collaborators/linear.png",
              description:
                "Linear-välitysjärjestelmän avulla teet toimeksiantosopimukset ja rajapinnan avulla siirrät toimeksiantajien tiedot, kohdetiedot, vuokrasopimukset ja jopa laskutustiedot Vuokranettiin. Vuokranet toimii loppuasiakkaan eli vuokralaisen... ",
            },
            // {
            //   id: 7,
            //   name: "Sähkösopimukset ja kotivakuutukset",
            //   logo: "company3.png",
            //   description: "Description of Company 3...",
            // },
            // {
          ],
        },
        {
          heading: "Muut",
          collaborators: [
            {
              id: 10,
              name: "Suomen vuokranantajat",
              logo: "/images/collaborators/linear.png",
              description:
                "Linear-välitysjärjestelmän avulla teet toimeksiantosopimukset ja rajapinnan avulla siirrät toimeksiantajien tiedot, kohdetiedot, vuokrasopimukset ja jopa laskutustiedot Vuokranettiin. Vuokranet toimii loppuasiakkaan eli vuokralaisen... ",
            },
            {
              id: 11,
              name: "Korjausvelkalaskuri",
              logo: "/images/collaborators/",
              description:
                "Korjausvelkaraportti näyttää taloyhtiön tehdyt ja tulevat remontit sekä arvioi suunniteltujen remonttien kustannukset.",
            },
            {
              id: 12,
              name: "Herculex lakineuvonta",
              logo: "/images/collaborators/",
              description: "Lakineuvontaa",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.collaborator-image img {
  max-width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Add a shadow to the image */
  border-radius: 5px;
}

p {
  font-size: 16px !important;
}
</style>
